import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './ArticleAuthorDetails.module.scss';

const ArticleAuthorDetails = (props) => {
  const { name, details, imgData, slug } = props;

  return (
    <div>
      <div className={style.authorDetailsWrapper}>
        <div className={style.nameRow}>
          {imgData && imgData?.images && (
            <Link className="clearLinkStyle d-block" to={slug}>
              <GatsbyImage
                image={imgData}
                alt={name}
                title={name}
                className={style.image}
              />
            </Link>
          )}
          <Link className={`${style.name} clearLinkStyle d-block`} to={slug}>
            {name}
          </Link>
        </div>
        <div className={style.details}>{details}</div>
      </div>
    </div>
  );
};

ArticleAuthorDetails.propTypes = {
  name: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  imgData: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
};

export default ArticleAuthorDetails;
